import React from "react";
import logo from "../../assets/images/LOGO.png";
import "./Logo.sass";
import { Link } from "react-router-dom";
const Logo = () => {
  return (
    <Link to="/">
      <div className="navbar-logo">
        <img src={logo} alt="BES" />
      </div>
    </Link>
  );
};

export default Logo;
