import { Grid } from "@mui/material";
import React from "react";
import "./Footer.sass";
import Logo from "../Logo/Logo";
import location from "../../assets/images/location.png";
const Footer = () => {
  return (
    <Grid container className="footer">
      <Grid item xs={8} sx={{ margin: "auto" }}>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={3}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Logo />
          </Grid>
          <Grid
            item
            sx={{
              margin: {
                xs: "1rem 0",
                md: "0",
              },
            }}
            sm={6}
          >
            <p>tu.trancong@outlook.com</p>
          </Grid>
          <Grid item xs={12} sm={3}>
            <div className="location">
              <img src={location} alt="location" />
              Toronto, Canada
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Footer;
