import MenuIcon from "@mui/icons-material/Menu";
import { Button, Grid, Stack } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import { signOut } from "../../features/authSlice";
import Logo from "../Logo/Logo";
import SocialBar from "../SocialBar/SocialBar";
import "./Navbar.sass";

const Navbar = () => {
  const isAuth = useSelector((store) => store.auth.isAuth);
  const dispatch = useDispatch();

  const [isMobileNavOpened, setIsMobileNavOpened] = useState(false);

  const navList = [
    {
      to: "/",
      title: "home",
    },
    {
      to: "/portfolio",
      title: "portfolio",
    },
    {
      to: "/about",
      title: "about",
    },
    {
      to: "",
      title: "contacts",
    },
  ];

  return (
    <div
      className="navbar"
      style={{
        backgroundColor: `${
          isMobileNavOpened ? "var(--bg-dark-grey)" : "transparent"
        }`,
      }}
    >
      <Grid
        container
        style={{
          justifyContent: { xs: "space-between", md: "flex-start" },
        }}
      >
        <Grid item xs={3} style={{ paddingLeft: "1rem" }}>
          <Logo />
        </Grid>
        <Grid
          item
          sm={9}
          sx={{
            display: { xs: "none", md: "flex" },
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Stack direction="row" spacing={6}>
            <>
              {isAuth && (
                <Button onClick={() => dispatch(signOut())}>Sign out</Button>
              )}
            </>
            {navList.map((item, index) => {
              return (
                <div key={index}>
                  {item.title === "contacts" ? (
                    <ScrollLink
                      to="contact-form"
                      className="navbar-item"
                      spy={true}
                      smooth={true}
                      duration={800}
                    >
                      Contacts
                    </ScrollLink>
                  ) : (
                    <Link to={item.to} className="navbar-item">
                      {item.title}
                    </Link>
                  )}
                </div>
              );
            })}
            <div className="navbar-item">
              <SocialBar />
            </div>
          </Stack>
        </Grid>
        <Grid
          item
          xs={9}
          sx={{
            display: { xs: "flex", md: "none" },
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Button
            size="medium"
            onClick={() => setIsMobileNavOpened(!isMobileNavOpened)}
          >
            <MenuIcon />
          </Button>
        </Grid>
      </Grid>
      <div style={{ paddingBottom: `${isMobileNavOpened ? "3rem" : "0"}` }}>
        <Stack
          direction="column"
          spacing={6}
          alignItems={"end"}
          className={`${!isMobileNavOpened && "hide"}`}
        >
          {navList.map((item, index) => {
            return (
              <div
                key={index}
                style={{ marginTop: "1rem", padding: "0 1.2rem" }}
              >
                {item.title === "contacts" ? (
                  <ScrollLink
                    to="contact-form"
                    className="navbar-item"
                    spy={true}
                    smooth={true}
                    duration={800}
                    onClick={() => setIsMobileNavOpened(false)}
                  >
                    Contacts
                  </ScrollLink>
                ) : (
                  <Link
                    to={item.to}
                    className="navbar-item"
                    onClick={() => setIsMobileNavOpened(false)}
                  >
                    {item.title}
                  </Link>
                )}
              </div>
            );
          })}
        </Stack>
      </div>
    </div>
  );
};

export default Navbar;
